<template>
    <div>
        <img class="example-headImg" src="../assets/image/aboutus/06-0banner.png"/>
        <div class="head-explain">
            <div class="head-explain-title">梦天门数智科技</div>
            <div class="head-explain-content">诚信、创新、卓越、共赢</div>
            <div class="head-explain-content" style="margin-top:-3rem">成为客户首选的科技创新伙伴。</div>
        </div>
        <div class="head-list">
            <div class="head-list-item head-list-item-ischeck" style="margin-left:26.3rem"><span class="title">公司简介</span></div>
            <div class="head-list-item"><span class="title">愿景使命</span></div>
            <div class="head-list-item"><span class="title">资质荣誉</span></div>
            <div class="head-list-item"><span class="title">客户精选</span></div>
            <div class="head-list-item"><span class="title">合作伙伴</span></div>
        </div>
        <div class="container-1" style="display:flex">
            <div class="aboutus-box">
                <div class="left">
                    <img src="../assets/image/aboutus/06-0小图.png"/>
                </div>
                <div class="right">
                    <div class="title1">公司简介</div>
                    <div class="title2">&emsp;&emsp;公司是一家专业服务于中国公共卫生信息化的领先企业，为卫生健康产业提供信息化建设，面向疾病预防控制、卫生及食药监督、专项健康体检、医防协同等关系民生健康的细分领域提供专业的行业软件、智能化设备、运维及相关服务。公司主要客户为各级卫生行政部门、疾病预防控制机构、卫生监督机构、药品药械监督机构、市场监管机构、医疗服务机构及其他政府部门、企事业单位。 公司坚持以“科技护卫健康”为企业发展愿景，依据国家公共卫生体系架构设计和卫生信息化战略部署，将行业业务与新一代信息技术相结合，拓展数据获取渠道，有效整合行业数据资源，应用“互联网+”的思路，丰富数据应用场景，为行业用户和社会公众提供更多样化、定制化的数据服务，建立协作互助、分级有序的新型公共卫生信息化体系</div>
                </div>
            </div>
        </div>
        <div class="container-2" style="height: 50rem;">
            <div class="abouts-title" style="margin: 0 0 0 26.2rem;padding-top: 6rem;">愿景使命</div>
            <div class="aboutus-box2">
                <div class="card">
                    <img src="../assets/image/aboutus/06-0使命.svg"/>
                    <div class="title1">使命</div>
                    <div class="title2">推动企业数字化重塑和创新，<br/>助力企业成为数字化转型和创新的受益者</div>
                </div>
                <div class="card">
                    <img src="../assets/image/aboutus/06-0愿景.svg"/>
                    <div class="title1">愿景</div>
                    <div class="title2">诚信、创新、卓越、共赢。 <br/>成为客户首选的科技创新伙伴。</div>
                </div>
                <div class="card">
                    <img src="../assets/image/aboutus/06-0价值观.svg"/>
                    <div class="title1">价值观</div>
                    <div class="title2">诚信、创新、卓越、共赢</div>
                </div>
            </div>

        </div>
        <div class="container-1">
            <div class="container-title" style="padding-top: 6rem;">资质荣誉</div>
            <el-carousel indicator-position="outside" height="50rem">
                <el-carousel-item v-for="item in box4List" :key="item.index" > 
                    <div class="aboutus-container">
                        <img v-if="item.url1" :src="item.url1"/>
                        <img v-if="item.url2" :src="item.url2"/>
                        <img v-if="item.url3" :src="item.url3"/>
                        <img v-if="item.url4" :src="item.url4"/>
                        <img v-if="item.url5" :src="item.url5"/>
                    </div>
                </el-carousel-item>
            </el-carousel>
            <div class="abouts-title" style="margin: 6rem 0 0 26.2rem;">愿景使命</div>
            <img class="aboutus-img1" src="../assets/image/aboutus/06-0客户摘选.png"/>
            <div class="abouts-title" style="margin: 6rem 0 0 26.2rem;">合作伙伴</div>
            <img class="aboutus-img2" src="../assets/image/aboutus/06-0合作伙伴.png"/>
        </div>

    </div>
</template>

<script>
export default {
    name: 'BizfocusPagesPurchaseCollaboration',

    data() {
        return {
            box4List:[
                {
                    url1:require("../assets/image/aboutus/06-0资质1-1.png"),
                    url2:require("../assets/image/aboutus/06-0资质1-2.png"),
                    url3:require("../assets/image/aboutus/06-0资质1-3.png"),
                    url4:require("../assets/image/aboutus/06-0资质2-1.png"),
                    url5:require("../assets/image/aboutus/06-0资质2-2.png"),
                },
                {
                    url1:require("../assets/image/aboutus/06-0资质3.png"),
                    url2:require("../assets/image/aboutus/06-0资质4.png"),
                    url3:require("../assets/image/aboutus/06-0资质5.png"),
                    url4:require("../assets/image/aboutus/06-0资质6.png"),
                    url5:require("../assets/image/aboutus/06-0资质7.png")
                },
                {
                    url1:require("../assets/image/aboutus/06-0资质8.png"),
                    url2:require("../assets/image/aboutus/06-0资质9.png"),
                    url3:require("../assets/image/aboutus/06-0资质10.png"),

                }
            ]
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="less" scoped>
.aboutus-box{
    width: 140rem;
    display: flex;
    margin: 5.6rem 0 3.6rem 26rem;
    .left{
        width: 69.9rem;
        height: 46.4rem;
        img{
            width: 69.9rem;
            height: 46.4rem;
        }
    }
    .right{
        width: 65.1rem;
        margin-left: 5rem;
        .title1{
            width: 12rem;
            height: 3.6rem;
            font-size: 3rem;
            font-family: 'CN_Medium',;
            font-weight: 500;
            color: #333333;
            line-height: 3.6rem;
        }
        .title2{
            width: 65.1rem;
            height: 44.3rem;
            font-size: 1.6rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 2.8rem;
            text-align: left;
            margin-top: 1.7rem;
        }
    }
}
.aboutus-box2{
    width: 140rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 10rem 0 0 26.6rem;
    .card{
        width: 45.6rem;
        height: 26.7rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 12px 0px rgba(233,233,233,0.6);
    }
    img{
        width: 6.4rem;
        height: 6.4rem;  
        margin-top: 4rem;
    }
    .title1{
        height: 2rem;
        font-size: 2rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #000000;
        line-height: 3rem;

        margin-top: 3rem;
    }
    .title2{
        height: 7.5rem;
        font-size: 1.6rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #4F4F4F;
        line-height: 2.6rem;


        margin-top: 3rem;
    }
}
.abouts-title{
    width: 14.4rem;
    height: 3.6rem;
    font-size: 3.6rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #333333;
    line-height: 5.4rem;
}
.aboutus-container{
    width: 140rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    margin: 5rem 0 0 26.4rem;
    img{
        width: 26rem;
        height: 35rem;
        margin-bottom: 2rem;
    }
}
.aboutus-img1{
    width: 141.8rem;
    height: 41.6rem;
    margin-top: 2.8rem;
}
.aboutus-img2{
    width: 142rem;
    height: 13rem;
    margin-top: 2.5rem;
}

.purchase-content-left{
    width:33rem;
    text-align: left;
    margin-left: 26rem;
    margin-top: 5.9rem;

}
.purchase-title{
    height: 2rem;
    font-size: 2rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #000000;
    line-height: 3rem;
}
.purchase-content{
    height: 7.7rem;
    font-size: 1.6rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #666666;
    line-height: 2.6rem;

    margin: 1.3rem 0 0 0.2rem;
}
.purchase-content-right{
    text-align: left;
    margin-top: 5.9rem;
    padding-bottom: 2rem;
}
.solution-img{
    width: 107.2rem;
    height: 69.1rem;
}
.benefits-img{
    width: 106.3rem;
    height: 66.2rem;
}
.solution-special{
    margin: 0 0 0 35.6rem;
    height: 1.6rem;
    font-size: 1.6rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #11A84F;
    line-height: 2.4rem;
}
.solution-special-img{
    width: 89.1rem;
    height: 1.1rem;
    margin: 1.2rem 0 6.1rem 6.4rem;
}
</style>